<template>
    <section class="h-100 pt-2 pr-2">
        <div class="row mx-0 h-100">
            <div class="menu-prinicipal h-100">
                <div class="row mx-0 align-items-center border-b-gris pr-2" style="height:54px;">
                    <div class="col my-auto">
                        <el-input v-model="buscar" clearable placeholder="Buscar por ID" size="small" class="br-20" @input="busqueda" />
                    </div>
                    <el-popover
                    placement="bottom"
                    width="250"
                    trigger="click"
                    popper-class="p-3 br-8"
                    >
                        <p class="f-14 text-general2 cr-pointer mb-3 f-500" @click="ordenar(1)">
                            Ordenar por fecha de cancelación
                        </p>
                        <p class="f-14 text-general2 cr-pointer f-500" @click="ordenar(2)">
                            Ordenar por fecha de entrega
                        </p>
                        <div slot="reference" class="btn-action border">
                            <i class="icon-swap-vertical-bold-down f-12" />
                        </div>
                    </el-popover>
                </div>
                <div class="col px-0 border-r-gris pt-3" style="width:400px;height:calc(100% - 100px);">
                    <!-- listado pedidos -->
                    <div class="row justify-center my-2">
                        <div class="col-auto mx-3 px-3 br-8 border bg-whitesmoke d-middle w-40" style="height:50px;">
                            <span class="text-general f-600 f-20 mr-2">{{ agregarSeparadoresNumero(pedidos.length) }}</span>
                            <span class="text-general2 f-17 text-center">Últ. 12 horas</span>
                        </div>
                        <div class="col-auto mx-3 px-3 br-8 border bg-whitesmoke d-middle w-40" style="height:50px;">
                            <span class="text-general f-600 f-20 mr-2">{{ agregarSeparadoresNumero(ult48Horas) }}</span>
                            <span class="text-general2 f-17 text-center">Últ. 48 horas</span>
                        </div>
                    </div>
                    <div class="row justify-center my-2">
                        <div class="col-auto mx-3 px-3 br-8 border bg-whitesmoke d-middle w-40" style="height:50px;">
                            <span class="text-general f-600 f-20 mr-2">{{ agregarSeparadoresNumero(ultSemana) }}</span>
                            <span class="text-general2 f-17 text-center">Últ. semana</span>
                        </div>
                        <div class="col-auto mx-3 px-3 br-8 border bg-whitesmoke d-middle w-40" style="height:50px;">
                            <span class="text-general f-600 f-20 mr-2">{{ agregarSeparadoresNumero(ultMes) }}</span>
                            <span class="text-general2 f-17 text-center">Últ. mes</span>
                        </div>
                    </div>
                    <div class="mb-2 overflow-auto custom-scroll" style="max-height: calc(100% - 87px);">
                        <div v-for="(ped, idx) in pedidosBackup" :key="idx" class="row mx-0 d-middle cr-pointer my-2 border-bottom pb-2 text-general" @click="seleccionar_pedido(ped.id)">
                            <div :class="`mx-2 px-1 br-5 ${activo==ped.id?'bg-general':''}`" style="height:75px;" />
                            <div :class="`col-11 px-0 tres-puntos f-15 py-2 br-10 ${activo==ped.id?'bg-light-f5':''}`">
                                <div class="row mx-0">
                                    <div class="col-5 px-2 d-middle">
                                        <i v-show="ped.id_pedido != null" class="icon-account-plus  f-18" />
                                        <p class="tres-puntos text-general f-300">ID. {{ ped.id }}</p>
                                        <i v-show="ped.manual === 1" class="icon-warehouse text-pink f-18" />
                                    </div>
                                    <div class="col-7 px-1 tres-puntos text-general text-right">
                                        <el-badge v-show="ped.cant_mensajes > 0" :value="ped.cant_mensajes" class="item mx-2 mt-2 mr-4">
                                            <i class="icon-message-text-outline cr-pointer f-20 text-gr-general" />
                                        </el-badge>
                                        <i :class="`f-18 ${icon_estado(ped.estado)}`" />
                                        {{ estado_pedido(ped.estado) }}
                                    </div>
                                </div>
                                <div class="row mx-0 my-1">
                                    <div class="col-8 px-2 text-general f-600">
                                        <p class="tres-puntos">{{ convertMoney(ped.valor_final,ped.idm_moneda) }}</p>
                                    </div>

                                    <div class="col-4 px-1 f-13">
                                        {{ ped.fecha_entrega | helper-fecha('DD MMM hh:mm a') }}
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-9 px-2 d-middle">
                                        <p class="tres-puntos f-13">{{ ped.direccion }}</p>
                                    </div>
                                    <div class="col-3 px-1">
                                        <b class="mr-1 text-general f-500">
                                            {{ ped.cant_productos }}
                                        </b>
                                        <span class="f-13">Productos</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 justify-center">
                        <div class="col-12 px-0">
                            <el-pagination
                            v-show="pedidos.length"
                            ref="paginacion"
                            background
                            class="text-center paginar-general_mini w-100"
                            :current-page="currentPage"
                            :page-size="10"
                            layout="prev,pager,next"
                            :total="pedidos.length"
                            @current-change="paginar"
                            @prev-click="paginar(10,false)"
                            @next-click="paginar(10,true)"
                            />
                        </div>
                    </div>
                    <div v-if="pedidos.length === 0" class="row h-70 mx-0 align-items-center justify-content-center">
                        <div class="text-center f-15 f-600 d-middle-center flex-column" style="width:225px;">
                            <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                                <img height="110" src="/img/no-imagen/clock.svg" alt="" />
                            </div>
                            <p>Aún no has recibidos, cancelado o rechazado pedidos</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-0 col menu-prinicipal  h-100" style="width: calc(100vw - 560px);">
                <listadoHistorial />
            </div>
        </div>
    </section>
</template>

<script>
import Estadisticas from "~/services/estadisticas";
import {mapGetters} from 'vuex'
import moment from 'moment';

export default {
    metaInfo: () =>({ title: 'Historial'}),
    name: 'IndexHistorialAdmin',
    sockets: {
        admin_pedidos_chats(value){ // Emite Cuando se escribe en tabla admin_pedidos_chats
            try {
                const {chat, pedidoChat} = JSON.parse(value)

                if(chat.created_by !== this.$usuario.id ){// cuando No tengo abierto  el chat del pedido actual entonces notifico
                // && parseInt(this.$route.params.id_pedido) !== chat.id_admin_pedido
                    if(pedidoChat.id === this.id_pedido){
                        // console.log('return false', this.sockets);
                        return false
                    }
                    let idx_pedido = this.pedidos.findIndex(e => e.id === pedidoChat.id)

                    if(idx_pedido >= 0){
                        let found = this.pedidos[idx_pedido]
                        found.cant_mensajes = found.cant_mensajes +1
                    }
                    if(this.pedido && this.pedido.id === chat.id_admin_pedido){
                        this.pedido.cant_mensajes = this.pedido.cant_mensajes + 1
                    }
                }

            } catch (e){
                this.error_catch(e)
            }
        }
    },
    components: {
        listadoHistorial: () => import('./listadoHistorial'),
    },
    data(){
        return {
            currentPage: 1,
            buscar: '',
            activo: -1,
            paginado: 0,
            pedidosBackup: [],
            roles_admin: ['admin','admin-vip'],
            tipo_decimal: true,
            fecha_entrega: [moment().subtract(1, 'day').toDate(),moment().subtract(1, 'day').toDate()],
        }
    },
    computed:{
        ...mapGetters({
            pedidos: 'pedidos/pedidos_cancelados_admin/pedidos',
            paginate: 'pedidos/pedidos_cancelados_admin/paginate',
            id_pedido: 'pedidos/pedidos_cancelados_admin/id_pedido', // solo actualizar chat
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
            ult48Horas: 'pedidos/pedidos_cancelados_admin/ult48Horas',
            ultSemana: 'pedidos/pedidos_cancelados_admin/ultSemana',
            ultMes: 'pedidos/pedidos_cancelados_admin/ultMes',
        }),
        rol(){
            return this.$usuario.rol_nombre
        },
    },
    watch:{
        id_cedis(val){
            this.$store.dispatch('pedidos/pedidos_cancelados_admin/set_data')
            this.listar_pedidos()
        },
    },
    mounted(){
        let that = this
        if(this.id_cedis === null){
            setTimeout(function(){
                that.listar_pedidos()
            }, 500);
        }else{
            that.listar_pedidos()
        }
    },
    methods: {
        busqueda(text){
            const change = () => {
                if(text.length > 1){
                    this.listar_pedidos(text)
                }else if(text.length === 0){
                    this.listar_pedidos()
                    this.seleccionar_pedido(null)
                }
            }
            this.delay(change)
        },
        async listar_pedidos(text){
            try {
                let params = {
                    query: text
                }
                await this.$store.dispatch('pedidos/pedidos_cancelados_admin/listar_pedidos',{params})
                
                this.pedidosBackup = this.pedidos.slice(0,10)
            } catch (e){
                this.error_catch(e)
            }
        },
        paginar(paginar,direccion=undefined){
            if(direccion===true){
                this.pedidosBackup = this.pedidos.slice(this.paginado,10)
                this.paginado = this.paginado+10
            }
            if(direccion===false){
                this.pedidosBackup = this.pedidos.slice(this.paginado-10,this.paginado)
                this.paginado = this.paginado-10
            }
            else if(direccion===undefined){
                var offset
                if(paginar==1){
                    offset = 0
                }else{
                    offset = (10 * paginar) - 10
                }
                this.pedidosBackup = this.pedidos.slice(offset,offset+10)

            }
            else{
                this.pedidosBackup = this.pedidos.slice(0,10)
            }
            this.currentPage = paginar
        },
        ordenar(filtro){
            if(filtro == 1){
                let pedidos = _.orderBy(this.pedidos,'created_at_estado','desc')
                this.$store.dispatch('pedidos/pedidos_cancelados_admin/ordenarPedidos',pedidos)
                this.paginar(1,undefined)
            }
            if(filtro == 2){
                let pedidos = _.orderBy(this.pedidos,['fecha_filtro','horario_filtro'],['desc','desc'])
                this.$store.dispatch('pedidos/pedidos_cancelados_admin/ordenarPedidos',pedidos)
                this.paginar(1,undefined)

            }
            this.currentPage = 1
        },
        seleccionar_pedido(id){
            this.activo = id
            this.$store.commit('pedidos/pedidos_historial_admin/set_id_pedido', id)
        },
        icon_estado(estado){
            switch (estado){
            case 4:
                return 'icon-shopping text-g-light'
            case 26:
                return 'icon-account-remove-outline text-general-red'
            case 27:
                return 'icon-cancel-circled-outline text-general-red'
            case 25:
                return 'icon-playlist-remove text-general-red'
            case 32:
                return 'icon-cancel-circled-outline text-general-red'
            default:
                return ''
            }
        },
        estado_pedido(estado){
            switch (estado){
            case 4:
                return 'Entregado'
            case 26:
            case 27:
                return 'Cancelado'
            case 25:
                return 'Rechazado'
            case 32:
                return 'Reclamo'
            default:
                return ''
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.menu-prinicipal{
    background: #fff;
}
</style>
