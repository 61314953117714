import axios from 'axios'

const API_PREFIX = "estadisticas";

const ENDPOINTS = {
    utilidad_x_meses: params => axios(`${API_PREFIX}/utilidad-x-meses`, { params }),
    informe: params => axios(`${API_PREFIX}/informe-productos`, { params }),
    ver_mas: params => axios(`${API_PREFIX}/ver-mas`, { params }),
    descargarVentas: params => axios(`${API_PREFIX}/descargar-ventas-excel`, { params }),
};

export default ENDPOINTS
